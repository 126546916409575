import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="about-text">
              <h2>QUIÉNES SOMOS</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 order-md-2">
            <div className="about-text">
              <p>{props.data ? props.data.paragraph1_1 : "cargando..."}</p>
              <p>{props.data ? props.data.paragraph1_2 : "cargando..."}</p>
              <p>{props.data ? props.data.paragraph1_3 : "cargando..."}</p>
            </div>
          </div>
          <div className="col-12 col-md-6 order-md-1">
            <img src="img/about/ruzafa.jpg" className="img-responsive" alt="" />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 col-md-6 order-md-3">
            <div className="about-text">
              <p>{props.data ? props.data.paragraph2_1 : "cargando..."}</p>
              <p>{props.data ? props.data.paragraph2_2 : "cargando..."}</p>
              <p>{props.data ? props.data.paragraph2_3 : "cargando..."}</p>
              <p>
                <span>Pueden ver nuestra </span>
                <a href="#location">situación en el mapa</a>
                <span> y contactarnos </span>
                <a href="#contact">aquí</a>
              </p>              
            </div>
          </div>
          <div className="col-12 col-md-6 order-md-4">
            <img src="img/about/ruzafa_fachadas.jpg" className="img-responsive" alt="" />
          </div>
          <div className="about-text-bye row">
            <div className="col-lg-12 col-md-12 col-sm-12">             
                ¡Mucha suerte y gracias por contar con nosotros!
            </div>
          </div>
        </div>       
      </div>
    </div>
  );
};
