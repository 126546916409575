import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { About } from "./components/about";
import { Prices } from "./components/prices";
import { Gallery } from "./components/gallery";
import { Location } from "./components/location";
import { Contact } from "./components/contact";
import { Policy } from "./components/policy";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  const [isPolicyVisible, setIsPolicyVisible] = useState(false);

  const togglePolicyVisibility = () => {
    setIsPolicyVisible(!isPolicyVisible);
  };
  

  return (

    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <About data={landingPageData.About} />    
      <Prices data={landingPageData.Prices} />     
      <Gallery data={landingPageData.Gallery} />
      <Location data={landingPageData.Location} />
      <Contact data={landingPageData.Contact} />

      <div id="footer">
        <div className="container text-center">
          <p>
            &copy;2024 Administracion Loteria 62 Ruzafa Valencia- 
            <a href="#!" onClick={togglePolicyVisibility}>Politica de Privacidad</a>
          </p>
        </div>
      </div>
      
      <Policy isVisible={isPolicyVisible} toggleVisibility={togglePolicyVisibility} />
    </div>
  );
};

export default App;
