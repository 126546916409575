import React from 'react';

export const Policy = ({ isVisible, toggleVisibility }) => {
  return (
    <div className={`policy ${isVisible ? 'visible' : 'hidden'}`}>
      <div id="policy" className="text-center">
        <div className="container">
          <div className="section-title section-title-policy">
            <h2>Política de privacidad</h2>
          </div>
          <div className="row text-policy">
            <p>
              Para registrarse y disfrutar de los servicios de de este canal de comunicación Web usted confirma que es mayor de edad.
            </p>
            <p>  
              Administracion62ruzafa.es es una Web de servicios propiedad del Punto Oficial de Venta de Loterías y Apuestas del Estado, Administración nº62 y Receptor nº83585, 
              situado en C/ Literato Azorin 15 – 46006 Valencia (Valencia).
            </p>
            <p> 
              Ofrece un conjunto de servicios para facilitar a nuestros clientes el envío de información, solicitud, pedidos y entrega de jugadas y Lotería, con comodidad, 
              seguridad, y sin necesidad de desplazarse físicamente.
              Integra los servicios más seguros, modernos y avanzados del comercio electrónico para el sector de Loterías y Apuestas, con emisión de documentos infalsificables
              ya que irán protegidos y certificados mediante “Firma Electrónica Avanzada” (DNIe) expedida y certificada por la FNMT-RCM (Fábrica Nacional de Moneda y Timbre. 
              Real Casa de la Moneda de España).
              De esta forma nuestros servicios, van a cumplir íntegramente con la Ley de Servicios de la Sociedad de la Información (LSSI) que establece el marco jurídico y 
              normativo que garantiza a prestadores y usuarios de servicios la seguridad necesaria para el uso de los medios electrónicos.
              Los datos que recogemos de nuestros clientes se utilizan únicamente para garantizar la seguridad del ciclo de vida de sus jugadas, desde la petición hasta el cobro de 
              premios. Lo anterior implica el envío de comunicaciones, siempre relacionadas con la recepción, validación y pago de premios de jugadas, así como la información 
              de todo lo relativo a botes o cualquier otra información de interés relativa a las Apuestas y Loterías del Estado.
            
              En caso de transferencias de premios los datos se envían a la entidad bancaria elegida por el cliente para garantizar la total seguridad del ingreso de sus premios en 
              cuentas de su propiedad. En los casos que establezca la ley también se facilitarán a la entidad pública empresarial Loterías y Apuestas del Estado.
            
              Cumple con la ley Orgánica 15/1999 del 13 de diciembre de Protección de Datos de Carácter Personal (LOPD) en lo referente a la confidencialidad y seguridad en el 
              almacenamiento y tratamiento de los datos personales. En cualquier momento se podrá solicitar la baja en nuestras bases de datos mediante el envío de un correo 
              electrónico solicitando dicha baja a la dirección que figura al pie de esta página.
            
              Advierte que la relación con sus clientes a través de este servicio Web es una relación comercial privada, exactamente igual que la que se establece a través del 
              punto de venta físico, y siempre sujeta a la normativa que Loterías y Apuestas del Estado establece para todos y cada uno de sus Juegos y para los establecimientos 
              que formamos su Red Oficial de Ventas.
          </p>
            
          <h3> 
              Información sobre Cookies
          </h3>
          <p>
              Una cookie es un pequeño fragmento de texto que los sitios web que visitas enví¬an al navegador y que permite que el sitio web recuerde 
              información sobre tu visita, como tu idioma preferido y otras opciones, lo que puede facilitar tu próxima visita y hacer que el sitio te resulte más útil. Las cookies desempeñan un papel muy importante, ya que sin ellas el uso de la Web serí¬a una experiencia mucho más frustrante.
              Utilizamos cookies con diversos fines, entre los que se incluyen recordar tus preferencias, aumentar la relevancia de los anuncios que ves, 
              contar el número de visitas que recibimos, ayudarte a registrarte en nuestros servicios y proteger tus datos.
          </p>
          <p>  
              TIPOS DE COOKIES: Utilizamos diferentes tipos de cookies para el funcionamiento de esta web. Una parte o la totalidad de las cookies identificadas a continuación se pueden 
              almacenar en tu navegador.
          </p> 
          <h3>
            Categorías de uso
          </h3>
          <h3>
            1. Preferencias
          </h3>
          <p>
            Estas cookies permiten que nuestros sitios web recuerden información que cambia el aspecto o el comportamiento del sitio como, por ejemplo, tu 
            idioma preferido o la región en la que te encuentras. La pérdida de la información almacenada en una cookie de preferencias puede hacer que la experiencia 
            del sitio web sea menos funcional, pero no debe afectar a su funcionamiento.
          </p>
          <h3>
            2. Cookies técnicas, de sesión y funcionales
          </h3>
          <p>
            Estas cookies son estrictamente necesarias para el uso de este sitio Web. Sin ellas la navegación y los procesos de compra no serían posibles. 
            Si estas cookies se bloquean o se eliminan, el sitio web no seguirá funcionando correctamente.
          </p>
          <h3>
            3. Cookies analíticas
          </h3>
          <p>
            En este sitio se utiliza Google Analytics como herramienta de análisis web, que principalmente permite que los propietarios de sitios web conozcan cómo interactúan los usuarios con su sitio web. Asimismo, habilita cookies en el dominio del sitio en el que te encuentras y utiliza un conjunto de cookies denominadas «__utma» y «__utmz» para recopilar información y elaborar informes sobre las visitas y su comportamiento, las compras realizadas, etc. El bloqueo de estas cookies no afecta al funcionamiento propio de esta página.
          </p>
          <h3>
            4. Cookies sociales
          </h3>
          <p>
            Las cookies sociales son necesarias para las redes sociales externas (Facebook, Google, Twitter, etc). Su función es controlar la interacción con los widgets sociales dentro de la página. El bloqueo de estas cookies no afecta al funcionamiento propio de esta página.
            Usted puede restringir, bloquear o borrar las cookies de esta web, utilizando su navegador. En cada navegador la operativa es diferente, la función de «Ayuda» le mostrará cómo hacerlo.
          </p>

          <p>
            Internet Explorer: windows.microsoft.com
          </p>
          <p>
            Firefox: support.mozilla.org
          </p>
          <p>
            Chrome: support.google.com
          </p>
          <p>
            Safari: www.apple.com
          </p>
          </div>          
          <div className="section-button-policy">
            <button className="btn btn-custom btn-lg" onClick={toggleVisibility}>Cerrar Política de privacidad</button>          
          </div>
        </div>
      </div>
      
    </div>
  );
};

