import React from "react";

export const Navigation = (props) => {
  // Función para manejar el cierre del menú
  const handleNavItemClick = () => {
    // Selecciona el elemento del menú desplegable y cierra el colapso
    const navbarCollapse = document.getElementById('bs-example-navbar-collapse-1');
    if (navbarCollapse.classList.contains('in')) {
      navbarCollapse.classList.remove('in');
    }
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll navigation-title" href="#page-top">
            Administración de lotería 62
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#about" className="page-scroll" onClick={handleNavItemClick}>
                Quiénes somos
              </a>
            </li>
            <li>
              <a href="#prices" className="page-scroll" onClick={handleNavItemClick}>
                Premios
              </a>
            </li>
            <li>
              <a href="#portfolio" className="page-scroll" onClick={handleNavItemClick}>
                Galería
              </a>
            </li>
            <li>
              <a href="#location" className="page-scroll" onClick={handleNavItemClick}>
                Dónde estamos
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll" onClick={handleNavItemClick}>
                Contacto
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
