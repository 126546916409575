import React from "react";

export const Location = (props) => {
  return (
    <div id="location" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title section-title-location">
          <h2>DÓNDE ESTAMOS</h2>
          <p>
            Venga a visitarnos y pruebe suerte.
          </p>          
        </div>
        
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 section-location-address">        
            <p>
                C/Literato Azorin 15, 46004, Valencia (España)
            </p>
            <p>
              Teléfono de contacto: 96 333 43 24
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6 section-location-map">           
            <iframe title="maps" src="https://www.google.com/maps/embed?pb=!4v1720975446905!6m8!1m7!1sBDY6EuZIFntXiKQITtAdoQ!2m2!1d39.46015915441291!2d-0.3741956004208393!3f30.121944677113895!4f-9.966698120430038!5f0.7820865974627469"
              width="100%"
              height="450" 
              style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 section-location-map">
            <iframe
                title="streetView"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3080.3991685307956!2d-0.37658282360822043!3d39.460310113293666!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd6048cb5c254ba9%3A0xa553d261a0f3d9f6!2sCalle%20Literato%20Azor%C3%ADn%2C%2015%2C%20Ensanche%2C%2046004%20Valencia!5e0!3m2!1ses!2ses!4v1720358004792!5m2!1ses!2ses"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
          <p></p>
          </div>          
        </div>
      </div>
    </div>
  );
};
